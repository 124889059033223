import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
import PodcastOutput from '../output/podcast-output';
import PodcastRecommendOutput from '../output/podcast-recommended-output';
import BooksOutput from '../output/books-output';
import BooksRecommendedOutput from '../output/books-recommended-output';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About Me`}</h1>
    <p>{`My name is Josh Young. I am a web developer living and working in Nashville, Tennessee. I enjoy all things tech related. I am not a huge blogger, but I enjoy writing small posts about what I am currently working with or technologies that I am using. Maybe something I say here will help someone in their journey, or at the very least provide a central place for me to document what I have learned when working through new projects.`}</p>
    <h3>{`My Job`}</h3>
    <p>{`For my day job, I develope web APIs and mobile applications for my employer,
`}<a parentName="p" {...{
        "href": "https://www.nfib.com/"
      }}>{`National Federation of Independent Business`}</a>{`. In a typical day, I work primarily with Xamarin/Maui, C#, and ASP.NET and their accompanying technologies such as build tools, testing frameworks, and the like. I primarily write "Line-of-Business" Applications (Enterprise Software Development).`}</p>
    <p>{`In the past, I have had the opportunity to work with many technologies including, but not limited to: PHP, Laravel, Objective-C, Ruby, JavaScript, and Python. On the `}<a parentName="p" {...{
        "href": "/resume"
      }}>{`resume`}</a>{` portion of this site, I have listed some of my job duties in more detail.`}</p>
    <h3><a id='hobbies'></a>{` Hobbies`}</h3>
    <p>{`Many of my hobbies relate to technology and development. I enjoy coding and designing web applications and web sites. When it comes to web development, I enjoy the whole stack from front end to back end. Therefore, I spend a significant amount of my free time keeping up with and learning the latest languages and frameworks. When possible, I try to implement this additional knowledge into the software stack that I use at work.`}</p>
    <p>{`I also spend some of my free time maintaining my home network, firewall, file server, git server, among other open-source applications. I enjoy Linux and FreeBSD so I spend time configuring these systems in my free time as well. As evident by some of my certifications and training, I also dabble in computer security. A good working knowledge of computer security and specifically web application security is very helpful as a web developer. If I am not working on servers or code, I spend my time playing my harmonica, biking, reading, or researching nutrition.`}</p>
    <h3><a id='podcasts'></a>{`Recommended Podcasts`}</h3>
    <p>{`Below I have listed the tech podcasts that I currently listen to or have listened to in the past. Podcasts are a great way to keep up with the latest changes in development. Hopefully this list will introduce a good podcast to someone who enjoys learning and keeping up with the latest changes in tech.`}</p>
    <PodcastOutput mdxType="PodcastOutput" />
    <h3><a id='podcast_episodes'></a>{`Great Podcast Episodes`}</h3>
    <PodcastRecommendOutput mdxType="PodcastRecommendOutput" />
    <h3><a id='books'></a>{`Books I've Read`}</h3>
    <p>{`Frequently, I try to delve deeper into specific aspects of tech that I want to learn more about. I find that buying a book about a particular technology can be a beneficial deep-dive experience. Below I have listed some of the books I have read over the years, which have contributed to where I am today (listed in alphabetical order and categorized below).`}</p>
    <BooksOutput mdxType="BooksOutput" />
    <h3><a id='books_i_recommend'></a>{`Books I Recommend`}</h3>
    <p>{`Of the software engineering, networking, security, and design books that I listed above, these are some of the books that I highly recommend.`}</p>
    <BooksRecommendedOutput mdxType="BooksRecommendedOutput" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      